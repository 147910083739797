import { useEffect, useState } from 'react';
import { firestore, storage } from '../firebase';
import { doc, getDoc } from 'firebase/firestore';
import { ref, getDownloadURL  } from "firebase/storage";
import { Link } from 'react-router-dom';
import Loading from '../static/loading.gif';

const Painting = (props) => {
    const [loading, setLoading] = useState(true);
    const [painting, setPainting] = useState(true);
    const [paintingUrl, setPaintingUrl] = useState('');

    const getPaintingById = async (id) => {
        const paintingRef = doc(firestore, "paintings", id);
        const document = await getDoc(paintingRef);
        const painting = document.data();

        return getPainting(painting);
    };

    const getPainting = async (paintingObject) => {
        const image = await downloadImage(paintingObject);
        setPaintingUrl(image);
        
        return paintingObject; 
    }

    const downloadImage = async (painting) => {
        const storageRef = ref(storage, painting.image);

        return getDownloadURL(storageRef)
                .then((url) => url)
                .catch((error) => {
                    console.error(error);

                    switch (error.code) {
                        case 'storage/object-not-found':
                            // File doesn't exist
                            break;
                        case 'storage/unauthorized':
                            // User doesn't have permission to access the object
                            break;
                        case 'storage/canceled':
                            // User canceled the upload
                            break;
                        case 'storage/unknown':
                            // Unknown error occurred, inspect the server response
                            break;

                        default:
                        break;
                    }
                });
    };

    useEffect(() => {
        if (props.painting) {
            getPainting(props.painting)
                .then((painting) => setPainting(painting))
                .then(() => setLoading(false));
        }
        else {
            getPaintingById(props.id)
                .then((painting) => setPainting(painting))
                .then(() => setLoading(false));
        }
    }, [])

  return (
      <div className="painting">
          {
            !loading 
            &&
                <div className="metadata">
                    <div className="text">
                        <h2>{painting.name}</h2>
                        <p>{painting.description}</p>

                        <Link to={"/painting/" + painting.id}>
                            <button className="btn white">
                                Read More
                            </button>
                        </Link>
                    </div>
                </div>
          }

            { loading && <img className="loading" alt="loading icon" src={Loading}/> }
            { !loading && <img className="painting-image" alt={painting.name} src={paintingUrl}/> }
      </div>
  );
}

export default Painting;
