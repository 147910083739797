import { Helmet } from "react-helmet";

const Contact = (props) => {
    return (
        <div className="app-container minheight contact-page">
            <Helmet>
                <title>Artiles Abstract Art — Contact</title>
            </Helmet>

            <h1>Contact</h1>
        </div>
    )
}

export default Contact;