import { Helmet } from "react-helmet";
import Carlie1 from '../static/carlie1.jpg';
import Carlie2 from '../static/carlie2.jpg';
import Carlie3 from '../static/carlie3.jpg';
import AboutPhoto from '../static/about.jpg';
import Navbar from "../components/Navbar";

const About = (props) => {
    return (
        <>
            <Navbar/>
            <div className="app-container minheight about-page">
                <Helmet>
                    <title>Artiles Abstract Art — About</title>
                </Helmet>

                <div>
                    <div className="image">
                        <img alt="Carlie holding her paintings" src={Carlie1}/>
                        <img alt="Carlie in workshop" src={Carlie2}/>
                        <img alt="Carlie photograph" src={AboutPhoto}/>
                        <img alt="Carlie photograph" src={Carlie3}/>
                    </div>

                    <div className="info">
                        <h1>Carlie Hardman, Artist</h1>
                        <h2>Born in Canberra, June 1965</h2>

                        <div>
                            <p>
                                As a youngster, Carlie was adventurous, outgoing and always quick to try her hand at new things without giving it a second thought. In her late teens tragedy struck her family when she lost her brother. His passing really changed her perspective on life. She decided to live her life to the fullest and chase her dreams.
                                
                                <br/><br/>
                                After dedicating 29 years of her life to the Department of Defence whilst raising a beautiful daughter as a single mum, it was time for Carlie to step out and do something completely different. 
                                
                                <br/><br/> 
                                Poor health restricted her activities which was frustrating and difficult given her extremely active lifestyle. Carlie had always dabbled in the art world, mainly with sketching, but she had aspirations that one day when she retired she may get some art lessons. With her restricting her outdoor activities, she started to search for an art course. Carlie struggled to find an art class, so with no guidance she turned to YouTube. After watching a few demonstrations, she went out in true Carlie style (full speed ahead!) and purchased an abundance of water colours, brushes and paper and so began her art journey. Carlie was pleasantly shocked with her results and the positive and encouraging feedback from friends and family inspired her to continue. 

                                <br/><br/>
                                That was nearly 10 years ago and today she is still experimenting with a variety of mediums. Carlie will tell you she draws inspiration from different events in her life including her gorgeous wife Jane who unconditionally supports her on every level.  Carlie has also been  passionate about crystals and the power of crystal healing since a young age which she believes has fuelled her artistic flair from landscaping, building, renovating and much more.  

                                <br/><br/>
                                Carlie’s art features lots of bright colours using acrylics, and her water colours are all landscapes. She would say her paintings speak from the heart and she is very humble when people compliment her work as she still finds it hard to believe she can create such meaningful artwork.
                            </p>
                        </div>
                    </div> 
                </div>
            </div>
        </>
    )
}

export default About;