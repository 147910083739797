import { Link } from "react-router-dom";
import Hamburger from 'hamburger-react'
import { useMediaQuery } from '@react-hook/media-query';
import { useState } from "react";
import Backdrop from '../static/backdrop.svg';

const menuItems = [
    { name: "Home", link: "/" },
    { name: "About", link: "/about" },
    { name: "Available Works", link: "/available-works" },
    { name: "Past Works", link: "/past-works" },
    { name: "Showcase", link: "/showcase" },
    { name: "My Journey", link: "/journey" },
]

const Navbar = ({ isTransparent }) => {
    const showFullMenu = useMediaQuery('only screen and (min-width: 1400px)');
    const [isOpen, setOpen] = useState(false);

    return (
        <>
            <div className={`navbar ${isOpen ? 'open' : 'closed'} ${isTransparent ? 'transparent' : ''}`}>
                <div className="inner app-container">
                    <Link to="/">
                        <div className="logo">
                            <h1>Artlies</h1>
                        </div>
                    </Link>

                    <div className="menu">
                        {
                            showFullMenu 
                            ?
                            <ul>
                                { menuItems.map((menuItem) => <Link key={menuItem.name} to={menuItem.link}><li>{menuItem.name}</li></Link>) }
                            </ul>
                            : <Hamburger toggled={isOpen} toggle={setOpen} />
                        }
                    </div>
                </div>
            </div>

            {
                !showFullMenu &&
                <div className={`fullscreen ${isOpen ? 'open' : 'closed'}`}>
                    <div className="app-container">
                        <ul>
                            <h5>MENU</h5>
                            <hr/>
                            { menuItems.map((menuItem) => <Link onClick={() => setOpen(false)} key={menuItem.name} to={menuItem.link}><li>{menuItem.name}</li></Link>) }
                        </ul>
                    </div>
                </div>
            }
        </>
    ); 
}

export default Navbar;