import { useEffect, useState } from 'react';
import { firestore, storage } from '../firebase';
import { doc, collection, addDoc, getDoc, updateDoc, deleteDoc } from 'firebase/firestore';
import { getStorage, uploadBytes, ref, getDownloadURL  } from "firebase/storage";
import { useNavigate } from "react-router-dom";
import Loading from '../static/loading.gif';
import Constants from '../constants/Constants';

const EditablePainting = (props) => {
    const [isCreating, setIsCreating] = useState(props.painting.isCreating);
    const [loading, setLoading] = useState(!isCreating);
    const [painting, setPainting] = useState(true);
    const [paintingUrl, setPaintingUrl] = useState('');
    const [name, setName] = useState(props.painting.name ?? "");
    const [description, setDescription] = useState(props.painting.description ?? "");
    const [link, setLink] = useState(props.painting.link ?? "");
    const [sold, setSold] = useState(props.painting.sold ?? false);
    const [hasSaved, setHasSaved] = useState(!isCreating);
    const [imageForUpload, setImageForUpload] = useState("");
    const navigate = useNavigate();

    const getPaintingById = async (id) => {
        const paintingRef = doc(firestore, "paintings", id);
        const document = await getDoc(paintingRef);
        const painting = document.data();

        return getPainting(painting);
    };

    const getPainting = async (paintingObject) => {
        const image = await downloadImage(paintingObject);
        setPaintingUrl(image);
        
        return paintingObject; 
    }

    const downloadImage = async (painting) => {
        const storageRef = ref(storage, painting.image);

        return getDownloadURL(storageRef)
                .then((url) => url)
                .catch((error) => {
                    console.error(error);

                    switch (error.code) {
                        case 'storage/object-not-found':
                            // File doesn't exist
                            break;
                        case 'storage/unauthorized':
                            // User doesn't have permission to access the object
                            break;
                        case 'storage/canceled':
                            // User canceled the upload
                            break;
                        case 'storage/unknown':
                            // Unknown error occurred, inspect the server response
                            break;

                        default:
                        break;
                    }
                });
    };

    useEffect(() => {
        if (!isCreating) {
            if (props.painting) {
                getPainting(props.painting)
                    .then((painting) => setPainting(painting))
                    .then(() => setLoading(false));
            }
            else {
                getPaintingById(props.id)
                    .then((painting) => setPainting(painting))
                    .then(() => setLoading(false));
            }
        }
    }, [])

    useEffect(() => {
        setName(painting.name ?? "");
        setDescription(painting.description ?? "");
        setLink(painting.link ?? "");
        setSold(painting.sold ?? false);
    }, [painting])

    const save = async () => {
        setLoading(true);

        if (isCreating) {
            if (imageForUpload !== "") {
                const storage = getStorage();
                const storageRef = ref(storage, imageForUpload.name);

                const snapshot = await uploadBytes(storageRef, imageForUpload);
                const downloadUrl = await getDownloadURL(snapshot.ref);

                await addDoc(collection(firestore, 'paintings'), {
                    name: name,
                    description: description,
                    link: link,
                    sold: sold,
                    image: downloadUrl,
                    type: props.painting.type
                });

                setName(name ?? "");
                setDescription(description ?? "");
                setLink(link ?? "");
                setSold(sold ?? "");
                setPaintingUrl(downloadUrl);

                setIsCreating(false);
                setHasSaved(true);
            }
            else {
                window.alert("Please upload an image");
            }
        } else {
            const document = doc(firestore, "paintings", painting.id);

            await updateDoc(document, {
                name: name,
                description: description,
                link: link,
                sold: sold,
            });

            setHasSaved(true);
        }

        setLoading(false);
    }

    const deletePainting = async () => {
        var r = window.confirm("Are you sure you want to delete?");

        if (!hasSaved) {
            return
        }

        if (r === true) {
            const document = doc(firestore, "paintings", painting.id);
            await deleteDoc(document);

            navigate("/", { replace: true })
        } else {
            return;
        }
    }

    const onHandleFileUpload = (e) => {
        const file = e.target.files[0];
        setImageForUpload(file);
    }

    return (
        <div className="editable">
                { loading && <img className="loading" alt="loading icon" src={Loading}/> }

                {
                    !isCreating &&
                    <div className="painting">
                        { (!loading && paintingUrl !== "") && <img className="painting-image" alt={painting.name} src={paintingUrl}/> }
                    </div>
                }


                <div className="editable-inner">
                    { 
                        isCreating &&
                        <>
                            <h2>New Painting</h2>
                            <label>Image</label>
                            <input type="file" onChange={onHandleFileUpload}/> 
                        </>
                    }

                    {
                        !loading 
                        &&
                        (
                            <>
                                <label>Name</label>
                                <input type="text" value={name} onChange={e => setName(e.target.value)}/>
                                <br/>

                                <label>Description</label>
                                <textarea rows="4" cols="50" type="text" value={description} onChange={e => setDescription(e.target.value)}/>
                                <br/>

                                <label>Link</label>
                                <input type="text" value={link} onChange={e => setLink(e.target.value)}/>
                                <br/>

                                {
                                    props.painting.type === Constants.dashboard.Painting
                                    &&
                                    <>
                                        <label>Sold?</label>
                                        <input type="checkbox" defaultChecked={sold} onChange={e => setSold(!sold)}/>
                                        <br/>
                                    </>
                                }

                                <button className="btn success" onClick={save}>Save</button>
                                { hasSaved && <button className="btn danger" onClick={deletePainting}>Delete</button> }
                            </>
                        )
                    }
                </div>
        </div>
    );
}

export default EditablePainting;
