import { Link } from "react-router-dom";
import { useContext } from "react";
import { AuthContext } from "./AuthProvider";
import { getAuth, signOut } from "@firebase/auth";
import { useNavigate } from "react-router-dom";
import Backdrop from '../static/backdrop.svg';

const Footer = (props) => {
    const { user } = useContext(AuthContext); 
    const auth = getAuth();
    const navigate = useNavigate();

    const logoutClick = () => {
        signOut(auth)
            .then(() => navigate("/", { replace: true }))
    }

    return (
        <>
        <div className="center interested">
            <div className="app-container">
                <h1>Interested in any of our work?</h1>
                <h3>We would love to hear from you. We do custom work and can customise colours to suit your home.</h3>

                <a href="https://www.facebook.com/Artlies-Abstract-Art-130907960875173" target="_blank" rel="noreferrer">
                    <button className="btn normal">
                        Connect with Facebook 
                    </button>
                </a>
            </div>
        </div>
            
        <div className="footer">
            <div className="inner app-container">
                <div className="logo">
                    <h1>Artlies</h1>
                </div>

                <div className="tag">
                    <p>Copyright &copy; {new Date().getFullYear()}, Artlies Abstract Art. All Rights Reserved.</p>
                </div>
            </div>

            <div className="inner app-container">
                <div>
                </div>

                <div className="tag">
                    { user == null && <Link to="/login">Login</Link>}
                    { user != null && <Link to="/dashboard">Dashboard</Link>}
                    { user != null && <a href="#" onClick={logoutClick}>Logout</a>}

                    <a rel="noreferrer" href="https://www.facebook.com/Artlies-Abstract-Art-130907960875173" target="_blank">
                        Connect with us on Facebook
                    </a>
                </div>
            </div>
        </div>
        </>
    ); 
}

export default Footer;