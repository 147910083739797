import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyC5fPTJecghAly05wSTN2_jyTirrumCVc8",
  authDomain: "artlies.firebaseapp.com",
  projectId: "artlies",
  storageBucket: "artlies.appspot.com",
  messagingSenderId: "638727616708",
  appId: "1:638727616708:web:12438b171a5c9e50f07191"
};

const app = initializeApp(firebaseConfig);

const storage = getStorage(app);
const firestore = getFirestore(app);

export { storage, firestore };