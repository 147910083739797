import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { useState, useContext, useEffect } from "react";
import { AuthContext } from "../components/AuthProvider";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import Navbar from "../components/Navbar";

const Login = (props) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const { user } = useContext(AuthContext); 

    const loginClick = () => {
        const auth = getAuth();

        signInWithEmailAndPassword(auth, email, password)
            .then(() => navigate("/dashboard", { replace: true }))
            .catch((error) => {
                setError('Something went wrong. We were unable to verify your credentials.');
            })
    };

    useEffect(() => {
        if (user != null) {
            navigate("/dashboard", { replace: true });
        }
    }, []);

    return (
        <>
            <Navbar/>
            <div>
                <Helmet>
                    <title>Artiles Abstract Art — Login</title>
                </Helmet>

                <h4 className="error">
                    {error != null && error}
                </h4>

                <div className="login-page app-container"> 
                    <div>
                        <h1>Login</h1>

                        <label>Email</label>
                        <input type="text" value={email} onChange={e => setEmail(e.target.value)}/>

                        <br/>

                        <label>Password</label>
                        <input type="password" value={password} onChange={e => setPassword(e.target.value)}/>
                        <button onClick={loginClick} className="btn">Login</button>    
                    </div>
                </div>
            </div>
        </>
    )
}

export default Login;