import { useEffect, useState } from 'react';
import { firestore } from '../firebase';
import { getDocs, query, collection, where } from 'firebase/firestore';
import Loading from '../static/loading.gif';
import Painting from '../components/Painting';
import { Helmet } from 'react-helmet';
import Navbar from '../components/Navbar';
import Constants from '../constants/Constants';

const PreviousWorks = () => {
    const [documents, setDocuments] = useState([]);
    const [loading, setLoading] = useState(true);

    const getAllPrevious = async () => {
        const previousPaintings = query(
            collection(firestore, "paintings"), 
            where("type", "==", Constants.dashboard.Painting), 
            where("sold", "==", true)
        );

        const snapshot = await getDocs(previousPaintings);

        const documentsFromDb = [];
        snapshot.forEach((doc) => documentsFromDb.push({ id: doc.id, ...doc.data()}));

        setDocuments(documentsFromDb);
    };

    useEffect(() => {
        getAllPrevious().then(() => setLoading(false));
    }, [])

    return (
        <>
            <Navbar/>
            <div className="works app-container">
                <Helmet>
                    <title>Artiles Abstract Art — Past Works</title>
                </Helmet>

                <h1>Past Artwork</h1>
                <h2>A list of currently previously sold art. Get in touch if you want to contract a similar item.</h2>

                <div className="paintings left">
                    { loading && <img className="loading" alt="loading icon" src={Loading}/> }
                    { !loading && documents.map((doc) => <Painting key={doc.id} painting={doc}/>) }
                </div>
            </div>
        </>
    )
}

export default PreviousWorks;