import './App.css';
import Navbar from './components/Navbar';
import Home from './pages/Home';
import Contact from './pages/Contact';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Footer from './components/Footer';
import AvailableWorks from './pages/AvailableWorks';
import PreviousWorks from './pages/PreviousWorks';
import Login from './pages/Login';
import DetailedView from './pages/DetailedView';
import Dashboard from './pages/Dashboard';
import { AuthProvider } from './components/AuthProvider';
import ScrollToTop from './components/ScrollToTop';
import { Helmet } from 'react-helmet';
import About from './pages/About';
import Icon from './static/icon.png';
import PaintingDashboard from './pages/dashboards/PaintingDashboard';
import NotFound from './pages/NotFound';
import Constants from './constants/Constants';
import Showcase from './pages/Showcase';
import Journey from './pages/Journey';

function App() {
  return (
    <AuthProvider>
      <BrowserRouter>
        <Helmet>
              <meta charSet="utf-8" />
              <title>Artiles Abstract Art — Official Website</title>
              <meta name="description" content="The Official Website of Artlies Abstract Art. A Canberra-based artist with original art for sale." />
              <link rel="icon" type="image/png" href={Icon} sizes="16x16" />
        </Helmet>

        <div className="app-root">
          <ScrollToTop />

          <Routes>
            <Route exact path="/" element={<Home/>} />
            <Route exact path="/about" element={<About/>} />
            <Route exact path="/contact" element={<Contact/>} />
            <Route exact path="/available-works" element={<AvailableWorks/>} />
            <Route exact path="/past-works" element={<PreviousWorks/>} />
            <Route exact path="/showcase" element={<Showcase/>} />
            <Route exact path="/journey" element={<Journey/>} />
            <Route exact path="/login" element={<Login/>} />
            <Route exact path="/dashboard" element={<Dashboard/>} />
            <Route exact path="/dashboard/paintings" element={<PaintingDashboard dashboardType={Constants.dashboard.Painting}/>} />
            <Route exact path="/dashboard/showcase" element={<PaintingDashboard dashboardType={Constants.dashboard.Showcase}/>} />
            <Route exact path="/dashboard/journey" element={<PaintingDashboard dashboardType={Constants.dashboard.Journey}/>} />
            <Route exact path="/painting/:id" element={<DetailedView/>} />

            <Route path="*" element={<NotFound/>} />
          </Routes>
        </div>

        <Footer/>
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;
