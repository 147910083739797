import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../components/AuthProvider";
import { Link, useNavigate } from "react-router-dom";
import { firestore } from '../../firebase';
import { getDocs, query, collection, where } from 'firebase/firestore';
import EditablePainting from "../../components/EditablePainting";
import Loading from '../../static/loading.gif';
import { Helmet } from "react-helmet";
import Navbar from "../../components/Navbar";
import Constants from "../../constants/Constants";

const PaintingDashboard = (props) => {
    const [documents, setDocuments] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const { user } = useContext(AuthContext); 
    const { dashboardType } = props;

    useEffect(() => {
        if (user == null) {
            console.log(user);
            navigate("/", { replace: true })
        }
    }, [user, navigate])

    const getAllPaintings = async () => {
        const paintings = query(collection(firestore, "paintings"), where("type", "==", dashboardType)); 
        const snapshot = await getDocs(paintings);

        const documentsFromDb = [];
        snapshot.forEach((doc) => documentsFromDb.push({ id: doc.id, ...doc.data()}));

        setDocuments(documentsFromDb);
    };

    useEffect(() => {
        getAllPaintings().then(() => setLoading(false));
    }, []);

    const createNew = () => {
        const documentsCopy = [ ...documents ];

        const newDocument = {
            name: '',
            description: '',
            link: '',
            image: '',
            sold: false,
            isCreating: true,
            type: dashboardType
        }

        documentsCopy.unshift(newDocument);

        setDocuments(documentsCopy);
    };

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    return (
        <>
        <Navbar/>
        <div className="dashboard">
            <Helmet>
                <title>Artiles Abstract Art — {capitalizeFirstLetter(dashboardType)} Dashboard</title>
            </Helmet>

            <div className="app-container">
                <Link to="/dashboard">Back</Link>
                <h1>Dashboard</h1>
                <h3>Editing: {capitalizeFirstLetter(dashboardType)}</h3>

                <button onClick={createNew} className="btn success">+ Create New</button>
            </div>

            { loading && <img className="loading" alt="loading icon" src={Loading}/> }

            <div className="paintings">
                { !loading && documents.map((doc) => <EditablePainting key={doc.id} painting={doc}/>) }
            </div>
        </div>
        </>
    )
}

export default PaintingDashboard;