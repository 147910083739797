import { useEffect, useState } from 'react';
import { firestore } from '../firebase';
import { doc, getDocs, query, collection, where } from 'firebase/firestore';
import { Link } from 'react-router-dom';
import Loading from '../static/loading.gif';
import Painting from '../components/Painting';
import { Helmet } from 'react-helmet';
import Backdrop from '../static/backdrop.svg';
import Navbar from '../components/Navbar';
import Constants from '../constants/Constants';

const AvailableWorks = () => {
    const [documents, setDocuments] = useState([]);
    const [loading, setLoading] = useState(true);

    const getAllAvailable = async () => {
        const availablePaintings = query(
            collection(firestore, "paintings"), 
            where("type", "==", Constants.dashboard.Painting), 
            where("sold", "==", false)
        );

        const snapshot = await getDocs(availablePaintings);

        const documentsFromDb = [];
        snapshot.forEach((doc) => documentsFromDb.push({ id: doc.id, ...doc.data()}));

        setDocuments(documentsFromDb);
    };

    useEffect(() => {
        getAllAvailable().then(() => setLoading(false));
    }, [])

    return (
        <>
            <Navbar/>

            <div className="works app-container">
                <Helmet>
                    <title>Artiles Abstract Art — Available Works</title>
                </Helmet>

                <h1>Available Artwork</h1>
                <h2>A list of currently available art. Get in touch if you want to purchase an item.</h2>

                <div className="paintings left">
                    { loading && <img className="loading" alt="loading icon" src={Loading}/> }
                    { !loading && documents.map((doc) => <Painting key={doc.id} painting={doc}/>) }
                </div>
            </div>
        </>
    )
}

export default AvailableWorks;