import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../components/AuthProvider";
import { Link, useNavigate } from "react-router-dom";
import EditablePainting from "../components/EditablePainting";
import Loading from '../static/loading.gif';
import { Helmet } from "react-helmet";
import Navbar from "../components/Navbar";

const Dashboard = () => {
    const navigate = useNavigate();
    const { user } = useContext(AuthContext); 

    useEffect(() => {
        if (user == null) {
            console.log(user);
            navigate("/", { replace: true })
        }
    }, [user, navigate])

    return (
        <>
        <Navbar/>
        <div className="dashboard">
            <Helmet>
                <title>Artiles Abstract Art — Dashboard</title>
            </Helmet>

            <div className="app-container">
                <h1>Dashboard</h1>
            </div>

            <div className="app-container"> 
                <ul>
                    <Link to="/dashboard/paintings"><li>Paintings</li></Link>
                    <Link to="/dashboard/showcase"><li>Showcase</li></Link>
                    <Link to="/dashboard/journey"><li>My Journey</li></Link>
                </ul>
            </div>
        </div>
        </>
    )
}

export default Dashboard;