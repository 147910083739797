import { useParams } from 'react-router';
import { useEffect, useState } from 'react';
import { firestore, storage } from '../firebase';
import { doc, getDoc } from 'firebase/firestore';
import { ref, getDownloadURL  } from "firebase/storage";
import { Link } from 'react-router-dom';
import Loading from '../static/loading.gif';
import { Helmet } from 'react-helmet';
import Navbar from '../components/Navbar';

const DetailedView = (props) => {
    const { id } = useParams();

    const [loading, setLoading] = useState(true);
    const [painting, setPainting] = useState(true);
    const [paintingUrl, setPaintingUrl] = useState('');

    const getPaintingById = async (id) => {
        const paintingRef = doc(firestore, "paintings", id);
        const document = await getDoc(paintingRef);
        const painting = document.data();

        return getPainting(painting);
    };

    const getPainting = async (paintingObject) => {
        const image = await downloadImage(paintingObject);
        setPaintingUrl(image);
        
        return paintingObject; 
    }

    const downloadImage = async (painting) => {
        const storageRef = ref(storage, painting.image);

        return getDownloadURL(storageRef)
                .then((url) => url)
                .catch((error) => {
                    console.error(error);

                    switch (error.code) {
                        case 'storage/object-not-found':
                            // File doesn't exist
                            break;
                        case 'storage/unauthorized':
                            // User doesn't have permission to access the object
                            break;
                        case 'storage/canceled':
                            // User canceled the upload
                            break;
                        case 'storage/unknown':
                            // Unknown error occurred, inspect the server response
                            break;

                        default:
                        break;
                    }
                });
    };

    useEffect(() => {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera

        getPaintingById(id)
            .then((painting) => setPainting(painting))
            .then(() => setLoading(false));
    }, []);

    return (
        <>
            <Navbar/>
            
            <div className="detailed-view">

                {
                    painting 
                    &&
                    <Helmet>
                        <title>Artiles Abstract Art {painting.name ? '— ' + painting.name : ''}</title>
                    </Helmet>
                }

                <div className="app-container detailed-painting">
                    { loading && <img className="loading" alt="loading icon" src={Loading}/> }

                    <div className="image">
                        { !loading && <img className="painting-image" alt={painting.name} src={paintingUrl}/> }
                    </div>

                    <div className="metadata">
                        <h1>{painting.name}</h1>
                        <p>{painting.description}</p>


                        { 
                            painting.link &&
                            <a href={painting.link} rel="noreferrer" target="_blank">
                                <button className="btn normal">View On Facebook</button>
                            </a> 
                        }
                    </div>
                </div>
            </div>
        </>
    )
};

export default DetailedView;