import { Helmet } from "react-helmet";
import Navbar from "../components/Navbar";

const NotFound = (props) => {
    return (
        <>
            <Navbar/>
            <div>
                <Helmet>
                    <title>Artiles Abstract Art — 404</title>
                </Helmet>

                <div className="minheight app-container">
                    <h1>404 - Page Not Found.</h1>
                </div>

                <br/><br/>
            </div>
        </>
    )
}

export default NotFound;