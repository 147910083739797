import Painting from '../components/Painting';
import { useEffect, useState } from 'react';
import { firestore } from '../firebase';
import { getDocs, query, collection, limit } from 'firebase/firestore';
import { Link } from 'react-router-dom';
import About from '../static/about.jpg';
import Loading from '../static/loading.gif';
import Backdrop from '../static/backdrop.svg';
import Background from '../static/background.JPG';
import Navbar from '../components/Navbar';

const Home = (props) => {
    const [documents, setDocuments] = useState([]);
    const [loading, setLoading] = useState(true);

    const getTop5Docs = async () => {
        const top5 = query(collection(firestore, "paintings"), limit(5));
        const snapshot = await getDocs(top5);

        const documentsFromDb = [];
        snapshot.forEach((doc) => documentsFromDb.push({ id: doc.id, ...doc.data()}));

        setDocuments(documentsFromDb);
    };

    useEffect(() => {
        getTop5Docs().then(() => setLoading(false));
    }, [])

    return (
        <>
            <div className="container">
                <Navbar isTransparent/>

                <div className="minheight center app-container">
                    <div className="page">
                        <div className="hero">
                            <h1>Original <br/> abstract <br/> art. <br/>—</h1>

                            <h2>A Canberra-based artist dedicated to creating meaningful and impactful art.</h2>
                        </div>
                    </div>
                </div>

                <div className="overlay"/>
                <img alt="background" className="background-image" src={Background}/>
            </div>

            <div className="rectangles">
                <div/>
                <div/>
                <div/>
            </div>

            <div className="container">
                <div className="center about">
                    <div>
                        <img src={About} alt="Carlie Profile"/>
                    </div>

                    <div className="info">
                        <h1>Meet Carlie.</h1>
                        <h2>Carlie Hardman, Artist</h2>

                        <p>
                            As a youngster, Carlie was adventurous, outgoing and always quick to try her hand at new things without giving it a second thought. 
                            <br/><br/>
                            After dedicating 29 years of her life to the Department of Defence whilst raising a beautiful daughter as a single mum, it was time for Carlie to step out and do something completely different. 
                        </p>

                        <br/>

                        <Link to="/about">
                            <button className="btn normal">
                                Read more about Carlie
                            </button>
                        </Link>
                    </div>
                </div>

                <div className="center artwork">
                    <div>
                        <h1>Original Artwork</h1>
                        <h2>Hand-crafted and designed with love.</h2>
                    </div>

                    <div className="paintings">
                        { loading && <img className="loading" alt="loading icon" src={Loading}/> }
                        { !loading && documents.map((doc) => <Painting key={doc.id} painting={doc}/>) }
                    </div>

                    <div className="buttons">
                        <Link to="/past-works">
                            <button className="btn normal">
                                View Past Artwork 
                            </button>
                        </Link>

                        <Link to="/available-works">
                            <button className="btn normal">
                                View Available Artwork 
                            </button>
                        </Link>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Home;